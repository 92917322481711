import { useEffect, useState } from "react";
import actors from "../../api/actors.json";

import { Link } from "react-router-dom";

export default function Actors() {
  const [actorData, setActorData] = useState([]);

  useEffect(() => {
    setActorData(actors);
  }, []);

  return (
    <div className="md:grid md:grid-cols-2 mt-24">
      <Link to={`/CastAjans/Actors/${actorData[0]?.id}`}>
        <div
          key={actorData[0]?.id}
          className="container lg:w-[90%] lg:h-96 shadow-xl sm:grid sm:grid-cols-2 md:rounded-lg mb-10 mx-auto"
        >
          <img
            className="h-80 lg:h-96 w-full rounded-lg object-contain lg:object-cover"
            src={require("../../assets/burhan0.jpg")}
          />
          <div className="w-full lg:text-xl md:text-lg sm:text-sm">
            <h3 className="text-sm md:text-lg lg:text-xl my-4">
              {actorData[0]?.name + " " + actorData[0]?.surname + " "}
            </h3>
            <div className="flex md:flex-col gap-5 mx-5">
              <div className="w-full flex justify-between border-b-2 border-gray-300">
                <h3 className="text-sm lg:text-xl text-gray-600">Yaş</h3>
                <p className="text-left text-sm lg:text-xl text-gray-600">
                  {actorData[0]?.age}
                </p>
              </div>
              <div className="w-full flex justify-between border-b-2 border-gray-300">
                <h3 className="text-sm lg:text-xl text-gray-600">Göz Rengi</h3>
                <p className="text-left text-sm lg:text-xl text-gray-600">
                  {actorData[0]?.eyeColor}
                </p>
              </div>
              <div className="w-full flex justify-between border-b-2 border-gray-300">
                <h3 className="text-sm lg:text-xl text-gray-600">Boy</h3>
                <p className="text-left text-sm lg:text-xl text-gray-600">
                  {actorData[0]?.length}
                </p>
              </div>
              <div className="w-full flex justify-between border-b-2 border-gray-300">
                <h3 className="text-sm lg:text-xl text-gray-600">Kilo</h3>
                <p className="text-left text-sm lg:text-xl text-gray-600">
                  {actorData[0]?.weight}
                </p>
              </div>
            </div>
          </div>
        </div>
      </Link>
      <Link to={`/CastAjans/Actors/${actorData[1]?.id}`}>
        <div
          key={actorData[1]?.id}
          className="container lg:w-[90%] lg:h-96 shadow-xl sm:grid sm:grid-cols-2 md:rounded-lg mb-10 mx-auto"
        >
          <img
            className="h-80 lg:h-96 w-full rounded-lg object-contain lg:object-cover"
            src={require("../../assets/rauf0.jpg")}
          />
          <div className="w-full lg:text-xl md:text-lg sm:text-sm">
            <h3 className="text-sm md:text-lg lg:text-xl my-4">
              {actorData[1]?.name + " " + actorData[1]?.surname + " "}
            </h3>
            <div className="flex md:flex-col gap-5 mx-5">
              <div className="w-full flex justify-between border-b-2 border-gray-300">
                <h3 className="text-sm lg:text-xl text-gray-600">Yaş</h3>
                <p className="text-left text-sm lg:text-xl text-gray-600">
                  {actorData[1]?.age}
                </p>
              </div>
              <div className="w-full flex justify-between border-b-2 border-gray-300">
                <h3 className="text-sm lg:text-xl text-gray-600">Göz Rengi</h3>
                <p className="text-left text-sm lg:text-xl text-gray-600">
                  {actorData[1]?.eyeColor}
                </p>
              </div>
              <div className="w-full flex justify-between border-b-2 border-gray-300">
                <h3 className="text-sm lg:text-xl text-gray-600">Boy</h3>
                <p className="text-left text-sm lg:text-xl text-gray-600">
                  {actorData[1]?.length}
                </p>
              </div>
              <div className="w-full flex justify-between border-b-2 border-gray-300">
                <h3 className="text-sm lg:text-xl text-gray-600">Kilo</h3>
                <p className="text-left text-sm lg:text-xl text-gray-600">
                  {actorData[1]?.weight}
                </p>
              </div>
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
}
