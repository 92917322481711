import React from "react";

import { Routes, Route, Navigate } from "react-router-dom";
import { Actors, ActorDetail, ErrorPage } from "../pages";

export default function CastAjans() {
  return (
    <Routes>
      <Route path="/" element={<Navigate replace to="Actors" />} />
      <Route path="Actors" element={<Actors />} />
      <Route path="Actors/:actorId" element={<ActorDetail />} />
      <Route path="*" element={<ErrorPage />} />
    </Routes>
  );
}
