import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";

export default function HeroSection() {
  return (
    <div className="w-full h-full">
      <Carousel emulateTouch={true} showStatus={false}>
        <div className="w-full h-[600px]">
          <img
            className="w-[100%] h-[100%] object-cover "
            src={require("../../assets/cactusLogo1.jpg")}
          />
        </div>
        <div className="w-full h-[600px]">
          <img
            className="w-[100%] h-[100%] object-cover "
            src={require("../../assets/cactusLogo4.jpg")}
          />
        </div>
        <div className="w-full h-[600px]">
          <img
            className="w-[100%] h-[100%] object-cover "
            src={require("../../assets/cactusLogo3.jpg")}
          />
        </div>
      </Carousel>
      {/* <Slider {...settings}>
        <div className="h-[600px]">
          <img
            className="w-[100%] h-[100%] object-cover "
            src={require("../../assets/KaktüsLogoSiyah.jpg")}
          />
        </div>
        <div className="h-[600px]">
          <img
            className="w-[100%] h-[100%] object-cover "
            src={require("../../assets/KaktüsLogoYeşil.jpg")}
          />
        </div>
        <div className="h-[600px]">
          <img
            className="w-[100%] h-[100%] object-cover "
            src={require("../../assets/KaktüsLogoÇalışmaYüzeyi.jpg")}
          />
        </div>
      </Slider> */}
    </div>
  );
}
