import React, { useState, Fragment } from "react";

import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";

import { Link } from "react-router-dom";

import { Menu, Transition } from "@headlessui/react";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Header() {
  const [navFocus, setNavFocus] = useState(false);
  const [dashMenuFocus, setDashMenuFocus] = useState(false);
  const [dashMenuFocus2, setDashMenuFocus2] = useState(false);

  const handleNav = () => {
    setNavFocus(!navFocus);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const handledashMenuFocus = () => {
    setDashMenuFocus(!dashMenuFocus);
  };
  const handledashMenuFocus2 = () => {
    setDashMenuFocus2(!dashMenuFocus2);
  };

  const goToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div className="fixed w-full top-0 left-0 md:flex bg-white items-center justify-between md:px-10 px-7 z-40">
      <Link onClick={goToTop} to="/">
        <img src={require("../../assets/logo2.png")} />
      </Link>
      <div
        onClick={handleNav}
        className="absolute right-8 top-6 cursor-pointer md:hidden"
      >
        {navFocus ? <AiOutlineClose size={35} /> : <AiOutlineMenu size={35} />}
      </div>
      <ul
        className={`md:flex w-full h-screen md:h-24 md:items-center justify-between md:pb-0 pb-12 md:static absolute bg-white md:z-auto left-0 md:w-auto md:pl-0 pl-9 md:py-0 py-4 transition-all duration-500 ease-in ${
          navFocus
            ? "h-screen left-100 opacity-100"
            : "left-[-1000px] md:opacity-100 opacity-0"
        }`}
      >
        <li
          onClick={handleNav}
          className="md:ml-8 text-xl md:my-0 my-7 cursor-pointer hover:underline hover:text-primary"
        >
          <Link onClick={goToTop} to="/">
            Ana Sayfa
          </Link>
        </li>
        <li
          onClick={handleNav}
          className="md:ml-8 text-xl md:my-0 my-7 cursor-pointer hover:underline hover:text-primary"
        >
          <Link onClick={goToTop} to="AboutUs">
            Hakkımızda
          </Link>
        </li>
        <li className="md:ml-8 text-xl md:my-0 my-7 cursor-pointer hover:underline hover:text-primary">
          <Menu as="div" className="relative inline-block text-left">
            <Menu.Button className="hover:underline hover:text-primary">
              Hizmetleriz
            </Menu.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="origin-top-left absolute left-[-40px] md:left-[-60px] mt-2 w-60 rounded-md shadow-lg bg-white divide-y divide-gray-100 focus:outline-none">
                <div className="py-1">
                  <Menu.Item>
                    {({ active }) => (
                      <>
                        <Menu
                          as="div"
                          className="relative inline-block text-left"
                        >
                          <Menu.Button
                            onClick={handledashMenuFocus}
                            className={classNames(
                              active
                                ? "bg-gray-100 text-gray-900"
                                : "text-gray-700",
                              "block px-4 py-2 text-sm  hover:underline hover:text-primary"
                            )}
                          >
                            Reklam Tanıtım Filmi Hizmetleri
                          </Menu.Button>
                          <Transition
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                          >
                            <Menu.Items
                              className={`origin-top-left w-60 rounded-md shadow-lg bg-white divide-y divide-gray-100 focus:outline-none`}
                            >
                              <Menu.Item>
                                {({ active }) => (
                                  <>
                                    <Link
                                      onClick={handleNav}
                                      className={classNames(
                                        active
                                          ? "bg-gray-100 text-gray-900"
                                          : "text-gray-700",
                                        "block px-4 py-2 text-sm  hover:underline hover:text-primary"
                                      )}
                                      to="/DroneShooting"
                                    >
                                      Drone Çekim
                                    </Link>
                                    <Link
                                      onClick={handleNav}
                                      className={classNames(
                                        active
                                          ? "bg-gray-100 text-gray-900"
                                          : "text-gray-700",
                                        "block px-4 py-2 text-sm  hover:underline hover:text-primary"
                                      )}
                                      to="/CorporatePromotionalFilm"
                                    >
                                      Reklam/Tanıtım Filmi
                                    </Link>
                                    <Link
                                      onClick={handleNav}
                                      className={classNames(
                                        active
                                          ? "bg-gray-100 text-gray-900"
                                          : "text-gray-700",
                                        "block px-4 py-2 text-sm  hover:underline hover:text-primary"
                                      )}
                                      to="/AdvertisingTrailer"
                                    >
                                      Kurumsal Tanıtım Filmi
                                    </Link>
                                  </>
                                )}
                              </Menu.Item>
                            </Menu.Items>
                          </Transition>
                        </Menu>
                        <Link
                          onClick={handleNav}
                          className={classNames(
                            active
                              ? "bg-gray-100 text-gray-900"
                              : "text-gray-700",
                            "block px-4 py-2 text-sm  hover:underline hover:text-primary"
                          )}
                          to="ConcertFestivalOrganization"
                        >
                          Konser/Festival Organizasyonu
                        </Link>
                        <Link
                          onClick={handleNav}
                          className={classNames(
                            active
                              ? "bg-gray-100 text-gray-900"
                              : "text-gray-700",
                            "block px-4 py-2 text-sm  hover:underline hover:text-primary"
                          )}
                          to="Management"
                        >
                          Menajerlik
                        </Link>
                        <Link
                          onClick={handleNav}
                          className={classNames(
                            active
                              ? "bg-gray-100 text-gray-900"
                              : "text-gray-700",
                            "block px-4 py-2 text-sm  hover:underline hover:text-primary"
                          )}
                          to="CastAjans"
                        >
                          Cast Ajans
                        </Link>
                        <Menu as="div" className="inline-block text-left">
                          <Menu.Button
                            onClick={handledashMenuFocus2}
                            className={classNames(
                              active
                                ? "bg-gray-100 text-gray-900"
                                : "text-gray-700",
                              "block px-4 py-2 text-sm  hover:underline hover:text-primary"
                            )}
                          >
                            Yazılım Hizmetleri
                          </Menu.Button>
                          <Transition
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                          >
                            <Menu.Items
                              className={`origin-top-left w-60 rounded-md shadow-lg bg-white divide-y divide-gray-100 focus:outline-none`}
                            >
                              <Menu.Item>
                                {({ active }) => (
                                  <>
                                    <Link
                                      onClick={handleNav}
                                      className={classNames(
                                        active
                                          ? "bg-gray-100 text-gray-900"
                                          : "text-gray-700",
                                        "block px-4 py-2 text-sm  hover:underline hover:text-primary"
                                      )}
                                      to="/WebDesign"
                                    >
                                      Web Hizmetleri
                                    </Link>
                                    <Link
                                      onClick={handleNav}
                                      className={classNames(
                                        active
                                          ? "bg-gray-100 text-gray-900"
                                          : "text-gray-700",
                                        "block px-4 py-2 text-sm  hover:underline hover:text-primary"
                                      )}
                                      to="/MobileApplication"
                                    >
                                      Mobil Uygulama
                                    </Link>
                                  </>
                                )}
                              </Menu.Item>
                            </Menu.Items>
                          </Transition>
                        </Menu>
                      </>
                    )}
                  </Menu.Item>
                </div>
              </Menu.Items>
            </Transition>
          </Menu>
        </li>
        <li
          onClick={handleNav}
          className="md:ml-8 text-xl md:my-0 my-7 cursor-pointer  hover:underline hover:text-primary"
        >
          <Link onClick={goToTop} to="/Contact">
            İletişim
          </Link>
        </li>
      </ul>
    </div>
  );
}
