import React from "react";

export default function MobileApplication() {
  return (
    <div className="w-full mx-auto px-5 mt-24 mb-10">
      <div className="md:w-[80%] h-full shadow-xl md:rounded-lg p-5 mx-auto">
        <div class="w-full py-8 ">
          <img src={require("../../assets/Mobil0.webp")} />
          <p class="text-black mt-2 text-lg">
            Mobil internet kullanımı her geçen gün artarken mobil özelliklerin
            de hızlı bir gelişim gösterdiğini görüyoruz. Mobil uygulamaların
            kurumsal kimliği temsil etmedeki gücü ve hizmet kolaylaştırıcılığı
            açısından sağladığı avantajlar dijital dünyada mobil uygulama sahibi
            olmayı da zorunlu kılıyor. Kullanıcı deneyimi yüksek profesyonel bir
            tasarımla hazırlanmış Android ve İOS uygulama tasarımlarıyla mobil
            uygulamanızı tasarlayarak yönetim sürecinde her türlü teknik desteği
            sağlıyoruz.
          </p>
        </div>
        <div class="w-full py-8 ">
          <h3 class="text-black text-xl font-semibold tracking-tight">
            Mobil Uygulama Geliştirme Adımları
          </h3>
          <ul className="list-disc">
            <li>Sektörel ve Dijital İhtiyaç Analizi</li>
            <li>Rakip Analizi</li>
            <li>Kullanıcı Deneyimi Testi</li>
            <li>Programlama</li>
            <li>Son Test</li>
            <li>Yayınlama</li>
          </ul>
          <div>
            <div>
              <h3 class="text-black text-xl font-semibold tracking-tight">
                Sektörel ve Dijital İhtiyaç Analizi
              </h3>
              <p class="text-black mt-2 text-lg">
                Eğitim, ticaret, eğlence gibi farklı sektörlerin dinamiklerini
                esas alan hızlı, işlevsel ve kullanışlı mobil uygulama
                tasarımlarını hazırlıyoruz. Talep ettiğiniz özellikleri,
                uygulama da ki hedeflerinizi ve sektörünüzün uygulamaya
                elverişliliği gibi konuları dikkate alarak sizlere ve
                müşterilerinizi en kullanışlı mobil uygulamayı sunmayı
                hedefliyoruz.
              </p>
            </div>
            <div class="w-full py-8 ">
              <h3 class="text-black text-xl font-semibold tracking-tight">
                Rakip Analizi
              </h3>
              <p class="text-black mt-2 text-lg">
                Dijital dünyada rakiplerin tecrübelerinden ve yaptığı
                çalışmalardan haberdar olmak gerekli teknik ve dijital bilgiye
                sahip olduğumuzda oldukça kolaydır. Bu anlamda sahip olduğumuz
                bilgi ve tecrübeleri kullanarak sektörünüzde fark yaratan
                rakiplerinizin mobil uygulamalarını analiz ediyor ve eksiği
                olmayan daha farklı özelliklere sahip Android ve İOS
                uygulamalarını sizin ve hedef kitlenizin kullanımına sunuyoruz.
              </p>
            </div>
            <div class="w-full py-8 ">
              <h3 class="text-black text-xl font-semibold tracking-tight">
                Programlama
              </h3>
              <p class="text-black mt-2 text-lg">
                Sektör analizi, rakip analizi ve değerlendirme süreçlerini
                tamamladıktan sonra istenen uygulamanın programlanma aşamasına
                geliyor. Sürecin başından beri planlı ve programlı yürüttüğümüz
                taslağı gerekli işlemlerle programlıyor, test aşamalarını
                yapıyor ve değerlendirmenize sunuyoruz. Geri bildirimlerinizle
                birlikte yeniden gözden geçirip gerekli revizeleri
                gerçekleştiriyoruz.
              </p>
            </div>
            <div class="w-full py-8 ">
              <h3 class="text-black text-xl font-semibold tracking-tight">
                Son Test
              </h3>
              <p class="text-black mt-2 text-lg">
                Android ve İOS uygulamanın programlanmasından sonraki
                değerlendirme süreci tamamlandığında uygulamayı kapsamlı bir
                testle son değerlendirmeye tabi tutuyoruz. Bu değerlendirme
                sonucunda uygulamanın çalışma biçimi, uygulamayla
                yapabilecekleriniz gibi konularda detaylı bilgi sunan bir
                raporda size veriliyor.
              </p>
            </div>
            <div class="w-full py-8 ">
              <h3 class="text-black text-xl font-semibold tracking-tight">
                Yayınlama
              </h3>
              <p class="text-black mt-2 text-lg">
                Uygulamanızın tasarım süreci ve değerlendirmeleri tamamlandıktan
                sonra hazır hale getirilerek mobil uygulama marketlerinde
                yayınlanır. Google Play, App Store gibi marketler aracılığıyla
                hedef kullanıcılarınız uygulamayı indirerek kullanabilecektir.
              </p>
            </div>
            <div class="w-full py-8 ">
              <h3 class="text-black text-xl font-semibold tracking-tight">
                Uygulama Geliştirme
              </h3>
              <p class="text-black mt-2 text-lg">
                Uygulamaların kullanımı ve geri dönütleriyle ilgili Google
                Analytics gibi çeşitli araçlar vasıtasıyla kullanım durumuyla
                ilgili raporlar sunuyoruz. Bu raporlar neticesinde ve uygulamayı
                kullananların önerileri doğrultusunda uygulamalara yeni
                özellikler ekleyerek sürekli gelişime sahip uygulama yönetimini
                destekliyoruz.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
