import React from "react";
import { HeroSection } from "../../components";

import { Link } from "react-router-dom";

export default function Home() {
  return (
    <div className="mt-24">
      <HeroSection />
    </div>
  );
}
