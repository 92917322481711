import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import actors from "../../api/actors.json";

import { useParams } from "react-router-dom";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function ActorDetail() {
  const { actorId } = useParams();
  const [actor, setActor] = useState();

  useEffect(() => {
    actors.map((data) => {
      if (data.id == actorId) {
        setActor(data);
      }
    });
  }, [actorId]);

  const settings = {
    customPaging: function (i) {
      console.log(actor.images[i].imgSource);
      return (
        <a>
          <img
            src={require(`../../assets/${actor?.name.toLowerCase() + i}.jpg`)}
          />
        </a>
      );
    },
    dots: true,
    dotsClass: "slick-dots slick-thumb",
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <div className="w-full mx-auto px-5 mt-24 mb-10">
      <h3 className="text-center font-semibold text-lg md:text-xl lg:text-3xl my-10">
        {actor?.name + " " + actor?.surname}
      </h3>
      <div className="md:flex md:w-[80%] h-full shadow-xl md:rounded-lg p-5 mx-auto">
        <div className=" md:grid md:grid-cols-3">
          <div className="w-[95%] h-[400px] mb-10">
            <Slider className="w-full h-full" {...settings}>
              {actor?.images &&
                actor?.images.map((url, index) => (
                  <img
                    key={index}
                    className="md:w-full h-[390px] rounded-xl object-contain"
                    src={require(`../../assets/${
                      actor?.name.toLowerCase() + index
                    }.jpg`)}
                  />
                ))}
            </Slider>
          </div>
          <div className=" col-span-2">
            <div className="w-full lg:text-xl md:text-lg sm:text-sm">
              <h3 className="text-sm md:text-lg lg:text-xl my-4">
                Kişisel Bilegiler
              </h3>
              <div className="md:flex grid gap-5">
                <div className="w-full flex justify-between border-b-2 border-gray-300">
                  <h3 className="text-sm lg:text-xl text-gray-600">Yaş</h3>
                  <p className="text-left text-sm lg:text-xl text-gray-600">
                    {actor?.age}
                  </p>
                </div>
                <div className="w-full flex justify-between border-b-2 border-gray-300">
                  <h3 className="text-sm lg:text-xl text-gray-600">
                    Göz Rengi
                  </h3>
                  <p className="text-left text-sm lg:text-xl text-gray-600">
                    {actor?.eyeColor}
                  </p>
                </div>
                <div className="w-full flex justify-between border-b-2 border-gray-300">
                  <h3 className="text-sm lg:text-xl text-gray-600">Boy</h3>
                  <p className="text-left text-sm lg:text-xl text-gray-600">
                    {actor?.length}
                  </p>
                </div>
                <div className="w-full flex justify-between border-b-2 border-gray-300">
                  <h3 className="text-sm lg:text-xl text-gray-600">Kilo</h3>
                  <p className="text-left text-sm lg:text-xl text-gray-600">
                    {actor?.weight}
                  </p>
                </div>
              </div>
            </div>
            <div className="w-full lg:text-xl md:text-lg sm:text-sm border-b-2">
              <h3 className="text-sm md:text-lg lg:text-xl my-4">
                Tanıtım Videosu
              </h3>
              <h3 className="text-sm lg:text-lg my-4 text-gray-600">
                {!actor?.trailer === null
                  ? actor?.trailer
                  : "Tanıtım Vıdeosu Bulunmamaktadır"}
              </h3>
            </div>
            <div className="w-full lg:text-xl md:text-lg sm:text-sm border-b-2">
              <h3 className="text-sm md:text-lg lg:text-xl my-4">
                Tiyatro Çalışmaları
              </h3>
              <h3 className="text-sm lg:text-lg my-4 text-gray-600">
                {!actor?.theaterStudies === null
                  ? actor?.theaterStudies
                  : "Tiyatro Çalışması Bulunmamaktadır"}
              </h3>
            </div>
            <div className="w-full lg:text-xl md:text-lg sm:text-sm border-b-2">
              <h3 className="text-sm md:text-lg lg:text-xl my-4">
                Sinema Çalışmaları
              </h3>
              <h3 className="text-sm lg:text-lg my-4 text-gray-600">
                {!actor?.cinemaStudies === null
                  ? actor?.cinemaStudies
                  : "Sinema Çalışması Bulunmamaktadır"}
              </h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
