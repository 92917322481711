import React, { useEffect, useState } from "react";

import { InstagramEmbed } from "react-social-media-embed";

import ConcertFestivalApi from "../../api/ConcertFestivalApi.json";

export default function ConcertFestivalOrganization() {
  const [data, setData] = useState([]);

  useEffect(() => {
    setData(ConcertFestivalApi);
  }, []);
  return (
    <div className="mx-auto px-5 mt-24 mb-10 grid sm:grid-cols-2 md:grid-cols-3 ">
      {data.length &&
        data.map((data) => (
          <div
            key={data.id}
            className="md:flex md:w-[80%] h-full shadow-xl md:rounded-lg p-5 mx-auto"
          >
            <InstagramEmbed url={data.imgSource} />
          </div>
        ))}
    </div>
  );
}
