import { useState, useEffect } from "react";
import FooterMenus from "../../api/FooterMenus.json";
import FooterMenusCard from "../ui/FooterMenusCard";

import { FaInstagram, FaYoutube } from "react-icons/fa";
import { RiCactusFill } from "react-icons/ri";

export default function Footer() {
  const [footerMenuData, setFooterMenuData] = useState([]);

  useEffect(() => {
    setFooterMenuData(FooterMenus);
  }, []);
  return (
    <footer className="w-full h-full md:bg-white bg-gray-900 md:text-black text-white transition-all duration-500">
      <div className="grid gap-10 sm:grid-cols-3 items-start mx-32 sm:mx-5">
        <div className="w-full grid gap-y-4 sm:justify-center">
          <h6 className="text-lg text-primary">Bize Ulaşın</h6>
          <a
            href="mailto:cactusproduction0@gmail.com"
            className="flex gap-4 text-sm items-center hover:text-primary transition-colors"
          >
            <RiCactusFill size={22} />
            cactusproduction0@gmail.com
          </a>
          <a
            href="https://www.instagram.com/cactusproduction0"
            className="flex gap-4 text-sm items-center hover:text-igColor transition-colors"
          >
            <FaInstagram size={22} /> Instagram
          </a>
          <a
            href="https://www.youtube.com/channel/UCrtpiib1hspmPFOnGzjHx0g"
            className="flex gap-4 text-sm items-center hover:text-ytColor transition-colors"
          >
            <FaYoutube size={22} /> Youtube
          </a>
        </div>
        {footerMenuData.map((footerMenu, index) => (
          <FooterMenusCard key={index} {...footerMenu} />
        ))}
      </div>
      <div className="w-10/12 h-full mx-auto flex justify-between border-t border-gray-100 mt-6 pt-6">
        <div className="text-md text-gray-700 flex gap-x-8">
          &copy; 2022 Cactus Yazılım Hizmetleri
          <a
            href="#"
            className="text-primary-brand-color hover:underline relative before:w-1 before:h-1 before:bg-black before:absolute before:-left-3 before:top-2 before:translate-y-1/2 before:rounded-full"
          >
            Tüm Hakları Saklıdır
          </a>
        </div>
        <nav className="flex gap-x-3">
          <a
            href="https://www.instagram.com/cactusproduction0"
            className="w-8 h-8 rounded-full flex items-center hover:text-igColor justify-center transition-colors"
          >
            <FaInstagram size={22} />
          </a>
          <a
            href="https://www.youtube.com/channel/UCrtpiib1hspmPFOnGzjHx0g"
            className="w-8 h-8 rounded-full flex items-center hover:text-ytColor justify-center transition-colors"
          >
            <FaYoutube size={22} />
          </a>
        </nav>
      </div>
    </footer>
  );
}
