import React from "react";

export default function Management() {
  return (
    <div className="w-full mx-auto px-5 mt-24 mb-10">
      <div className="md:w-[80%] h-full shadow-xl md:rounded-lg p-5 mx-auto">
        <div class="w-full py-8 ">
          <p class="text-black mt-2 md:text-xs lg:text-sm xl:text-lg ">
            Menajerlik konusunda yeni bir yapılanma içinde olan Cactus
            Production ihtiyaçlarınız doğrultusunda aşağıda listelenen
            hizmetleri sizlere sunmaktadır. Bu hizmetler hakkında detaylı bilgi
            almak için lütfen bizlere ulaşınız.
          </p>
        </div>
          <div className=" w-full mx-auto">
            <div className="w-full grid gap-y-5 justify-center rounded-lg p-5 mx-auto">
              <h3 className="font-bold text-center text-4xl">
                Sanatçı Menajerliği
              </h3>
              <div className=" grid md:grid-cols-2">
                <p className="grid gap-5 lg:text-xl text-sm md:text-lg">
                  Sanatçılara, eserlerinin icra aşamasında danışmanlık,
                  temsilcilik, konser organizasyonu ve tur menajerliği
                  hizmetlerini sunuyoruz.
                </p>
                <img
                  className="w-[90%] rounded-xl "
                  src={require("../../assets/manager0.jpg")}
                />
              </div>
            </div>
            <div className="w-full grid gap-y-5 justify-center rounded-lg p-5 mx-auto">
              <h3 className="font-bold text-center text-4xl">
                Konser Organizasyonu
              </h3>
              <div className=" grid md:grid-cols-2">
                <p className="grid gap-5 lg:text-xl text-sm md:text-lg">
                  Sanatçıların ve eserlerinin dinleyiciye ulaşmasındaki en
                  önemli unsur olan konser organizasyonlarının gerçekleşmesini
                  sağlıyoruz. Ülke içinde ve dışında bir çok şehirde mekanlarla
                  görüşüyor ve en uygun konser organizasyonlarını
                  gerçekleştirmeyi amaçlayarak, sanatçılarımızı en iyi şekilde
                  temsil ediyoruz.
                </p>
                <img
                  className="w-[90%] rounded-xl "
                  src={require("../../assets/manager1.jpg")}
                />
              </div>
            </div>
            <div className="w-full grid gap-y-5 justify-center rounded-lg p-5 mx-auto">
              <h3 className="font-bold text-center text-4xl">
                Tur Menajerliği
              </h3>
              <div className=" grid md:grid-cols-2">
                <p className="grid gap-5 lg:text-xl text-sm md:text-lg">
                  Sanatınızı icra etmeniz için size en rahat ve en uygun ortamı
                  sağlamayı hedefliyoruz. Konserleri için sanatçılara ulaşım,
                  konaklama gibi konuları organize edecek ve hem teknik
                  konularda, hem de sanatçının ihtiyaçları doğrultusunda, kısaca
                  her konuda sanatçıyı temsil ederek gerekli görüşmeleri
                  yapabilecek olan, donanımlı çalışma arkadaşları ile tur
                  menajerliği hizmeti sunuyoruz.
                </p>
                <img
                  className="w-[90%] rounded-xl "
                  src={require("../../assets/manage2.jpg")}
                />
              </div>
            </div>
            <div className="w-full grid gap-y-5 justify-center rounded-lg p-5 mx-auto">
              <h3 className="font-bold text-center text-4xl">
                Müzisyen Menajerliği
              </h3>
              <div className=" grid md:grid-cols-2">
                <p className="grid gap-5 lg:text-xl text-sm md:text-lg">
                  Sanatçı menajerliği kadar önemli olan müzisyen menajerliği,
                  maalesef ülkemizde uygulanmamaktadır. Bu konuda da bir ilke
                  imza atarak, yurt dışındaki müzisyenlerin projelerinize
                  entegrasyonunu sağlamaktayız. Müzisyenleri seçerken dikkat
                  ettiğimiz noktaların başında iş disiplini, kayıt konusundaki
                  ekipman yeterliliği ve stüdyo müzisyenliği konusunda evrensel
                  standartlara uygunluğu gelmektedir. Bu özelliklere sahip ve
                  çalışmaları ile bizleri etkileyen müzisyenlerin menajerliğini
                  yapmaktayız.
                </p>
                <img
                  className="w-[90%] rounded-xl "
                  src={require("../../assets/manage3.jpg")}
                />
              </div>
              <div className=" grid md:grid-cols-2">
                <p className="grid gap-5 lg:text-xl text-sm md:text-lg">
                  Projenizin prodüktörü/aranjörü ile birlikte menajerlik
                  hizmetini üstlendiğimiz müzisyenlerle SkyPe üzerinde
                  gerçekleştirilen “canlı ön çalışma” sonrası, daha önce
                  anlaşılmış zaman diliminde kanal kayıtlarını internet
                  üzerinden bizlere göndermektedirler. Bu ön çalışmalarda
                  müzisyenler ile iletişim dili olarak “İngilizce”yi
                  kullanmaktayız. Müzisyenlerle gerekli teması sağlamak ve
                  fikir/görüşlerinizi bildirmeniz için dilerseniz bu ön
                  çalışmalar sırasında sizlere yardımcı olmaktayız.
                </p>
                <img
                  className="w-[90%] rounded-xl "
                  src={require("../../assets/manage4.jpg")}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
  );
}
