import { Map } from "../../components";

export default function Contact() {
  return (
    <div className="w-full mt-24">
      <Map />
      <div className="mt-20 px-[10%] py-8 md:flex justify-between">
        <img
          src={require("../../assets/logo1.png")}
          className="hidden md:w-1/2 md:block"
        />
        <div className="w-full grid gap-y-5">
          <div className="grid gap-3">
            <h3 className="font-bold text-3xl md:text-5xl">Adres</h3>
            <h6 className="text-2xl md:text-3xl">Yeni mah. MB1-01 Kat:3 No:18 </h6>
          </div>
          <div className=" grid gap-3 mt-5">
            <h3 className="font-bold text-3xl md:text-5xl">Telefon</h3>
            <div className="flex flex-col gap-2">
              <a className="text-2xl md:text-3xl" href="tel:+905415180173">
                (+90)541 518 0173
              </a>
              <a className="text-2xl md:text-3xl" href="tel:+905426127971">
                (+90)542 612 7971
              </a>
            </div>
          </div>
          <div className="grid gap-3 mt-5">
            <h3 className="font-bold text-3xl md:text-5xl">E-Mail</h3>
            <a
              href="mailto:cactusproduction0@gmail.com"
              className="text-2xl md:text-3xl hover:text-primary"
            >
              cactusproduction0@gmail.com
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
