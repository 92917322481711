import React from "react";

import image from "../../assets/cactusLogo4.jpg";
export default function AboutUs() {
  return (
    <div class="w-full flex gap-x-8 items-start justify-center mt-20 px-[10%]">
      <div className="hidden lg:block w-full py-8">
        <img src={image} className="rounded-3xl" />
      </div>
      <div class="w-full py-8 ">
        <h3 class="text-black text-xl font-semibold tracking-tight">
          Hakkımızda
        </h3>
        <p class="text-black mt-2 md:text-xs lg:text-sm xl:text-lg ">
          Türkiye’nin en önde gelen organizasyon şirketlerinden olan Cactus
          Production yurt içinde ve yurt dışında yüzlerce başarılı
          organizasyona imza atarak 2019’dan beri büyüyerek faaliyet
          göstermektedir. Bu süre zarfında sanatseverlerin beklentilerini
          öncelikli hedef saymıştır. Türkiye’nin en büyük şirketleri ile çalışan
          Cactus Production konser, tiyatro, gösteri, festival, belediye
          etkinlikleri, tanıtım ve kongre organizasyonları, Logo tasarım,
          Kurumsal tanıtım filmi, Drone çekim, Menmajerlik, Web tasarım ve Web
          yazılım hizmetlerini vermektedir. Cactus Prorduction olarak amacımız;
          işimize gösterdiğimiz özen, saygı, sevgi ve sürekli gelişim ilkesi ile
          daima mükemmeli yakalayarak memnuniyetinizi yüksek seviyelere
          taşımaktır. Kültür ve sanatın kendini sürekli yenilediği gerçeğini
          unutmadan, kitlesel değişimleri takip ederek ve bunlar ışığında
          kendini de yenileyerek tüm bu alanlardaki faaliyetlerine devam
          etmektedir. Detaylı olarak deneyimlerimizi, şirketimizin kurumsal
          yapısını, profesyonel ekibimizi ve birlikte yapacağımız
          organizasyonları görüşmek için bilgilerimize iletişim bölümünden
          erişebilirsiniz.
        </p>
      </div>
    </div>
  );
}
