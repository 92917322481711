import React from "react";

export default function DroneShooting() {
  return (
    <div className="w-full mx-auto px-5 mt-24 mb-10">
      <div className="md:flex md:w-[80%] h-full shadow-xl md:rounded-lg p-5 mx-auto">
        <iframe
          src="https://www.youtube.com/embed/WFso7nq5JFI"
          frameborder="0"
          allow="autoplay; encrypted-media"
          title="video"
          className="md:w-full md:h-96 w-full h-full rounded-lg"
        />
        <h3 className="md:w-full md:h-96 h-full lg:text-xl md:text-lg sm:text-sm">
          Detay Proje Mühendislik Tanıtım Film
        </h3>
      </div>
    </div>
  );
}
