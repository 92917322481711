import React from "react";

export default function WebDesign() {
  return (
    <div className="w-full mx-auto px-5 mt-24 mb-10">
      <div className="md:w-[80%] h-full shadow-xl md:rounded-lg p-5 mx-auto">
        <div class="w-full py-8 ">
          <img src="https://d25tea7qfcsjlw.cloudfront.net/1918/kicerik/28357/b-2218.jpeg" />
          <p class="text-black mt-2 text-lg">
            “Web tasarımı nedir?" veya "web tasarım nedir?” sorusuna tek bir
            kısa cevap verecek olursak; Web tasarımı; görseller ve metinlerden
            oluşan web sayfası içeriklerinin web sitesi ziyaretçilerine bir
            düzen içerisinde sunulmasıdır. Web sitesi tasarımı, web tasarım
            projesi veya web sitesi yapımı gibi tanımlar da, web tasarımı ile eş
            anlamda kullanılır. Web tasarımı yapabilmek için öncelikle bir
            projeye ihtiyaç vardır. Firma, şahıs, marka veya ürün projenin esas
            konusunu oluşturur. Örneğin; doktor veya diş hekimi web sitesi,
            firma web sitesi, otel web sitesi, sağlık merkezi veya hastane web
            sitesi bir web tasarım projesidir. Buradan da anlaşıldığı gibi web
            tasarım kişi, kurum veya kuruluşları tanıtmak amacıyla yapılan, web
            sitesi sayfalarını kodlama, düzenleme ve internet ortamında
            yayınlama çalışmalarının tümüdür. Bu çalışmaları yapan uzman
            kişilere web tasarımcı, web tasarımcısı veya web master denir.
          </p>
        </div>
        <div class="w-full">
          <div className="w-full mb-10 grid gap-5">
            <h3 class="text-black text-3xl font-semibold tracking-tight">
              Web Tasarım Nasıl Yapılır?
            </h3>
            <ul className="list-disc grid gap-5">
              <li  className="text-lg">Projenin Belirlenmesi</li>
              <li  className="text-lg">Kurumsal Kimlik Çalışması</li>
              <li  className="text-lg">Grafik Tasarım Çalışması</li>
              <li  className="text-lg">Web Sayfalarının Kodlaması</li>
              <li  className="text-lg">Web Sitesinin Yayınlanması</li>
            </ul>
          </div>
          <div>
            <div>
              <h3 class="text-black text-xl font-semibold tracking-tight">
                Projenin Belirlenmesi
              </h3>
              <img src="https://d25tea7qfcsjlw.cloudfront.net/1918/kicerik/28357/33089.jpeg" />
              <p class="text-black mt-2 text-lg">
                Proje belirlenirken ihtiyaçlar göz önünde bulundurulur. Web
                sitesinin yapılış amacı, kişi yada kurumu tanıtmak olabildiği
                gibi firmanın ticari faaliyetlerini ön plana çıkarmak da
                olabilir. Bu noktada kapsamlı rakip firma analizleri yapılır.
                Proje belirlenirken web sitesinin yapılış amacı ve web sayfası
                içeriklerinin neler olacağı netlik kazanır.
              </p>
            </div>
            <div class="w-full py-8 ">
              <h3 class="text-black text-xl font-semibold tracking-tight">
                Kurumsal Kimlik Çalışması
              </h3>
              <p class="text-black mt-2 text-lg">
                Kurumsal kimlik çalışması, web tasarım projesi hayata geçmeden
                önce tamamlanması gereken çalışmalardır. Örneğin; firma logosu
                kurumsal kimlik çalışmasının bir parçasıdır. Web sitesinde
                kullanılan renkler firmanın kurumsal renkleri ile doğru orantılı
                olmalıdır. Logo, renk, slogan, vb. kurumsal kimlik öğeleri web
                tasarımında kullanılan en önemli unsurlardır.
              </p>
            </div>
            <div class="w-full py-8 ">
              <h3 class="text-black text-xl font-semibold tracking-tight">
                Grafik Tasarım Çalışması
              </h3>
              <p class="text-black mt-2 text-lg">
                Grafik tasarım çalışmalarında, kurumsal kimlik çalışmaları esas
                alınır. Adobe Photoshop gibi piksel tabanlı resim ve fotoğraf
                düzenleme programları kullanılarak web sayfalarının görsel
                tasarımı yapılır. Web sayfalarında kullanılacak resim, fotoğraf,
                grafik gibi görseller özenle hazırlanarak uygun ve doğru
                yerlerde konumlandırılır.
              </p>
            </div>
            <div class="w-full py-8 ">
              <h3 class="text-black text-xl font-semibold tracking-tight">
                Web Sayfalarının Kodlaması
              </h3>
              <img src="https://d25tea7qfcsjlw.cloudfront.net/1918/kicerik/28357/33091.jpeg" />
              <p class="text-black mt-2 text-lg">
                Web sayfalarının kodlaması sürecinde HTML, CSS, JavaScript, SQL,
                PHP, Python ve ASP gibi çeşitli programlama dilleri kullanılır.
                Google Chrome, Mozilla Firefox, Opera veya Internet Explorer
                gibi internet tarayıcılarının web sitelerini
                görüntüleyebilmeleri için web sayfalarının bu dillerde
                kodlanması gerekir. Grafik tasarımı, web sayfalarının kodlaması
                ve web sayfası içeriklerinin oluşturulması sonucunda web
                sitesinin genel yapısı ve görünümü ortaya çıkar.
              </p>
            </div>
            <div class="w-full py-8 ">
              <h3 class="text-black text-xl font-semibold tracking-tight">
                Web Sitesinin Yayınlanması
              </h3>
              <img src="https://d25tea7qfcsjlw.cloudfront.net/1918/kicerik/28357/33093.png" />
              <p class="text-black mt-2 text-lg">
                Elbette web tasarım aşamaları içerisinde ifade edilebilecek
                farklı konular da vardır. Ancak en basit anlatımla bir web
                tasarım projesi bu süreçlerden geçer. Kapsamlı ve detaylı
                çalışmalar sonucunda web sitesi yayına hazırdır. Web sitesinin
                yayınlanabilmesi için domain olarak adlandırılan alan adına ve
                web sitesi sayfalarının barındırılacağı bir hosting hizmetine
                ihtiyaç vardır. Alan adı ve hosting hizmeti alındıktan sonra web
                sitesi yayınlanır. Web sitesi yayına alındıktan sonra arama
                motorlarına her bir web sayfasının tanımlandığı site haritasını
                göndermek gerekir. Google Arama Motoru günümüzde en çok
                kullanılan arama motorudur. Web sitesi yayına alındıktan sonra
                web sitesine ait site haritası Google’a gönderilir. Web sitesi
                indekslenerek Google arama sonuçlarında ziyaretçiler tarafından
                görüntülenir.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
