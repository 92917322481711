import React from "react";
import { HashRouter as Router, Routes, Route } from "react-router-dom";

import { Header, Footer, ScrollToTop } from "../components";

import {
  Home,
  AboutUs,
  Contact,
  ErrorPage,
  DroneShooting,
  AdvertisingTrailer,
  CorporatePromotionalFilm,
  ConcertFestivalOrganization,
  Management,
  WebDesign,
  MobileApplication,
} from "../pages";
import CastAjans from "./CastAjans";

export default function App() {
  return (
    <Router>
      <Header />
      <ScrollToTop />
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route path="AboutUs" element={<AboutUs />} />
        <Route path="DroneShooting" element={<DroneShooting />} />
        <Route path="AdvertisingTrailer" element={<AdvertisingTrailer />} />
        <Route
          path="CorporatePromotionalFilm"
          element={<CorporatePromotionalFilm />}
        />
        <Route
          path="ConcertFestivalOrganization"
          element={<ConcertFestivalOrganization />}
        />
        <Route path="Management" element={<Management />} />
        <Route path="CastAjans/*" element={<CastAjans />} />
        <Route path="WebDesign" element={<WebDesign />} />
        <Route path="MobileApplication" element={<MobileApplication />} />
        <Route path="CastAjans/*" element={<CastAjans />} />
        <Route path="Contact/*" element={<Contact />} />
        <Route path="*" element={<ErrorPage />} />
      </Routes>
      <Footer />
    </Router>
  );
}
