import React from "react";
import { Link } from "react-router-dom";

export default function FooterMenusCard({ title, items, link }) {
  return (
    <div className="w-full grid gap-y-3 sm:justify-center">
      <h6 className="text-lg text-primary">{title}</h6>
      {items.map((item, key) => (
        <Link key={key} to={item.link} className="text-sm hover:text-primary">
          {item.title}
        </Link>
      ))}
    </div>
  );
}
